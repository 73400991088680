<template>
  <div>
    <v-card
      flat
      class="pa-3 mt-2"
    >
      <v-card-text>
        <v-card-title class="pt-0 px-0">
          Informations personnelles
        </v-card-title>
        <v-divider></v-divider>
        <v-form class="multi-col-validation mt-6">
          <v-row>
            <v-col
              md="6"
              cols="12"
            >
              <v-text-field
                v-model="studentInfos.prenom"
                label="Prénom"
                dense
                outlined
              ></v-text-field>
            </v-col>

            <v-col
              md="6"
              cols="12"
            >
              <v-text-field
                v-model="studentInfos.nom"
                label="Nom"
                dense
                outlined
              ></v-text-field>
            </v-col>

            <v-col
              cols="12"
              md="6"
            >
              <v-text-field
                v-model="studentInfos.email"
                label="E-mail"
                dense
                outlined
              ></v-text-field>
            </v-col>

            <v-col
              cols="12"
              md="6"
            >
              <v-text-field
                v-model="studentInfos.tel"
                dense
                label="Téléphone"
                outlined
              ></v-text-field>
            </v-col>
            <v-col
              cols="12"
              md="6"
            >
              <v-text-field
                v-model="studentInfos.adresse.ligne1"
                label="Adresse première ligne"
              >
              </v-text-field>
              <v-text-field
                v-model="studentInfos.adresse.ligne2"
                label="Adresse seconde ligne"
              ></v-text-field>
              <v-text-field
                v-model="studentInfos.adresse.postcode"
                label="Code postal"
              ></v-text-field>
              <v-text-field
                v-model="studentInfos.adresse.ville"
                label="Ville"
              ></v-text-field>
              <v-text-field
                v-model="studentInfos.adresse.pays"
                label="Pays"
              ></v-text-field>
            </v-col>
            <v-col
              cols="12"
              md="6"
            >
              <v-text-field
                v-model="studentInfos.naissance"
                label="Date de naissance"
                type="date"
              ></v-text-field>

              <v-row>
                <v-col>
                  <v-autocomplete
                    v-model="studentInfos.statutPro"
                    class="mt-5"
                    :items="statutProList"
                    no-data-text="Pas d'entrée correspondante"
                    item-text="name"
                    item-value="slug"
                    dense
                    outlined
                    :clearable="true"
                    label="Statut Professionnel"
                  ></v-autocomplete>
                </v-col>
                <v-col>
                  <v-text-field
                    v-model="studentInfos.etude"
                    class="mt-5"
                    dense
                    outlined
                    label="Niveau d'étude"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-autocomplete
                v-if="editPage"
                v-model="editPartenaire"
                no-data-text="Pas d'entrée correspondante"
                :clearable="true"
                class="mt-5"
                :items="partenaires"
                item-text="name"
                item-value="id"
                dense
                outlined
                label="Partenaire"
                @change="updatePartenaire()"
              ></v-autocomplete>
              <v-autocomplete
                v-else
                v-model="partenaire"
                no-data-text="Pas d'entrée correspondante"
                :clearable="true"
                class="mt-5"
                :items="partenaires"
                item-text="name"
                item-value="id"
                dense
                outlined
                label="Partenaire"
              ></v-autocomplete>
            </v-col>
            <v-col cols="12">
              <v-textarea
                v-model="studentInfos.infos"
                outlined
                rows="3"
                label="Informations complémentaires"
              ></v-textarea>
            </v-col>
            <v-col
              cols="12"
            >
              <v-card-title class="pt-0 px-0">
                Formation
              </v-card-title>
              <v-divider></v-divider>
            </v-col>
            <v-col
              cols="12"
              md="6"
            >
              <v-text-field
                v-model="studentInfos.num_dossier"
                dense
                outlined
                label="Numéro de dossier EDOF"
              ></v-text-field>
            </v-col>

            <v-col
              cols="12"
              md="6"
            >
              <v-select
                v-model="studentInfos.status"
                dense
                outlined
                label="Status"
                :items="status"
              ></v-select>
            </v-col>
            <v-col
              cols="12"
              md="6"
            >
              <v-autocomplete
                v-model="studentInfos.formation"
                :items="formations"
                item-text="nom"
                item-value="id"
                dense
                outlined
                label="Formation"
              ></v-autocomplete>
            </v-col>

            <v-col
              cols="6"
              md="3"
            >
              <v-select
                v-model="studentInfos.dureeFormation"
                dense
                outlined
                label="Durée de la formation"
                :items="durees"
                item-text="name"
                item-value="value"
              ></v-select>
            </v-col>
            <v-col
              cols="6"
              md="3"
            >
              <v-slider
                v-if="studentInfos.visio"
                v-model="studentInfos.dureeVisio"
                hint="Durée du visio (en heures)"
                thumb-label="always"
                persistent-hint
                max="20"
                min="1"
              >
              </v-slider>
              <v-checkbox
                v-model="studentInfos.visio"
                label="Visio"
                class="mt-0"
                hide-details
              ></v-checkbox>
            </v-col>
            <v-col
              cols="6"
              md="3"
            >
              <v-menu
                v-model="dateDebutMenu"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    :value="formatedDate(studentInfos.debutFormation)"
                    label="Date de début de formation"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="studentInfos.debutFormation"
                  locale="fr-fr"
                  @input="dateDebutMenu = false"
                ></v-date-picker>
              </v-menu>
            </v-col>
            <v-col
              cols="6"
              md="3"
            >
              <v-menu
                v-model="dateFinMenu"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                :prepend-icon="icons.mdiCalendar"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    :value="formatedDate(studentInfos.finFormation)"
                    label="Date de fin de formation"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="studentInfos.finFormation"
                  locale="fr-fr"
                  @input="dateFinMenu = false"
                ></v-date-picker>
              </v-menu>
            </v-col>
            <v-col
              cols="12"
              md="3"
            >
              <v-text-field
                v-model="studentInfos.price"
                :prepend-inner-icon="icons.mdiCurrencyEur"
                dense
                label="Prix"
                outlined
              ></v-text-field>
            </v-col>
            <v-col
              cols="12"
              md="3"
            >
              <v-select
                v-model="studentInfos.tuteurs[0]"
                dense
                label="Tuteurs"
                item-text="nom"
                item-value="id"
                :items="tuteurs"
                outlined
                @change="updateTuteur"
              ></v-select>
            </v-col>
            <v-col
              v-if="editPage"
              cols="12"
              class="d-flex justify-space-between"
            >
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    color="secondary"
                    v-bind="attrs"
                    fab
                    class="mt-4"
                    v-on="on"
                    @click.prevent="$router.push({name:'student-new'})"
                  >
                    <v-icon>
                      {{ icons.mdiAccountPlus }}
                    </v-icon>
                  </v-btn>
                </template>
                <span>Nouvel apprenant</span>
              </v-tooltip>
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    v-bind="attrs"
                    right
                    color="error"
                    class="me-3 mt-4"
                    fab
                    v-on="on"
                    @click.prevent="deleteStudent"
                  >
                    <v-icon>{{ icons.mdiTrashCanOutline }}</v-icon>
                  </v-btn>
                </template>
                <span>Supprimer l'apprenant</span>
              </v-tooltip>
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    right
                    v-bind="attrs"
                    fab
                    color="primary"
                    class="me-3 mt-4"
                    v-on="on"
                    @click.prevent="$router.push({name:'student-single', id: studentInfos.id})"
                  >
                    <v-icon>
                      {{ icons.mdiAccountArrowRight }}
                    </v-icon>
                  </v-btn>
                </template>
                <span>Voir le profil</span>
              </v-tooltip>
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    right
                    v-bind="attrs"
                    fab
                    color="warning"
                    class="me-3 mt-4"
                    v-on="on"
                    @click.prevent="duplicateStudent"
                  >
                    <v-icon>
                      {{ icons.mdiAccountMultiplePlus }}
                    </v-icon>
                  </v-btn>
                </template>
                <span>Dupliquer l'apprenant</span>
              </v-tooltip>
              <v-btn
                right
                color="primary"
                class="me-3 mt-4"
                @click.prevent="updateStudent"
              >
                Mettre à jour
              </v-btn>
            </v-col>
            <v-col
              v-else
              cols="12"
              class="d-flex justify-space-between"
            >
              <v-btn
                color="secondary"
                outlined
                class="mt-4"
                type="reset"
              >
                Recommencer
              </v-btn>
              <v-btn
                right
                color="primary"
                class="me-3 mt-4"
                :disabled="disableBtn"
                @click.prevent="createStudent"
              >
                Sauvegarder
              </v-btn>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import {
  mdiAlertOutline,
  mdiCloudUploadOutline,
  mdiCalendar,
  mdiCurrencyEur,
  mdiTrashCanOutline,
  mdiAccountPlus,
  mdiAccountArrowRight,
  mdiAccountMultiplePlus,
} from '@mdi/js'

export default {
  props: {
    formations: {
      type: Array,
      default: () => {},
    },
    tuteurs: {
      type: Array,
      default: () => [],
    },
    partenaires: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      disableBtn: false,
      studentInfos: {
        statutPro: 'salarie',
        prenom: '',
        nom: '',
        email: '',
        tel: '',
        status: 'avantFormation',
        num_dossier: '',
        infos: '',
        tuteurs: [
          '',
        ],
        formation: '',
        dureeFormation: 4,
        visio: false,
        price: 0,
        naissance: new Date(),
        debutFormation: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
        finFormation: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
        adresse: {
          ligne1: '',
          ligne2: '',
          postcode: '',
          ville: '',
          pays: 'France',
        },
        dureeVisio: 1,
      },
      partenaire: 0,
      editPartenaire: null,
      durees: [
        {
          name: '3 semaines',
          value: 3,
        },
        {
          name: '1 mois',
          value: 4,
        },
        {
          name: '5 semaines',
          value: 5,
        },
        {
          name: '6 semaines',
          value: 6,
        },
        {
          name: '2 mois',
          value: 8,
        },
        {
          name: '9 semaines',
          value: 9,
        },
        {
          name: '10 semaines',
          value: 10,
        },
        {
          name: '3 mois',
          value: 12,
        },
      ],
      statutProList: [
        {
          name: 'Apprenti',
          slug: 'apprenti',
        },
        {
          name: 'Artisan',
          slug: 'Artisan',
        },
        {
          name: 'Auteur',
          slug: 'auteur',
        },
        {
          name: 'Commerçant',
          slug: 'commercant',
        },
        {
          name: 'Exploitant agricole',
          slug: 'exploitant-agricole',
        },
        {
          name: 'Fonctionnaire',
          slug: 'fonctionnaire',
        },
        {
          name: 'Intérimaire',
          slug: 'interimaire',
        },
        {
          name: 'Intermittent du spectacle',
          slug: 'intermittent-du-spectacle',
        },
        {
          name: 'Libéral',
          slug: 'liberal',
        },
        {
          name: 'Pigiste',
          slug: 'pigiste',
        },
        {
          name: 'Saisonnier',
          slug: 'saisonnier',
        },
        {
          name: 'Salarié',
          slug: 'salarie',
        },
        {
          name: 'Travailleur indépendant',
          slug: 'travailleur-independant',
        },
        {
          name: 'Travailleur intermittent',
          slug: 'travailleur-intermittent',
        },
        {
          name: 'Retraité',
          slug: 'retraite',
        },
        {
          name: 'Auto-entrepreneur',
          slug: 'auto-entrepreneur',
        },
        {
          name: 'Recherche d\'emploi',
          slug: 'recherche-emploi',
        },
      ],
      dateDebutMenu: false,
      dateFinMenu: false,
    }
  },
  computed: {
    editPage() {
      return ('id' in this.$route.params)
    },
    getCurrentPartenaire() {
      const partner = this.partenaires.filter(e => e.students.includes(this.studentInfos.id))
      if (partner.length > 0) {
        return partner[0].id
      }

      return 'Choisir le partenaire'
    },
  },
  mounted() {
    this.studentInfos.naissance = this.formatedDate(this.studentInfos.naissance)
    if ('id' in this.$route.params) {
      this.$http.get(`${process.env.VUE_APP_API_URL}/students/${this.$route.params.id}`)
        .then(stud => {
          this.studentInfos = stud.data
          if (this.studentInfos.facture) {
            delete this.studentInfos.facture
          }
          if (this.studentInfos.convention) {
            delete this.studentInfos.convention
          }
          if (this.studentInfos.creipef) {
            delete this.studentInfos.creipef
          }
          if (this.studentInfos.docsuivi) {
            delete this.studentInfos.docsuivi
          }
          if (this.studentInfos.task) {
            delete this.studentInfos.task
          }
          if (this.studentInfos.review) {
            delete this.studentInfos.review
          }
          this.editPartenaire = this.getCurrentPartenaire
        })
        .catch(error => console.error(error))
    }
  },
  methods: {
    formatedDate(e) {
      return this.$date(new Date(e), 'yyyy-MM-dd')
    },
    duplicateStudent() {
      const duplicate = this.studentInfos
      const toDelete = ['id', 'formation', 'num_dossier']
      toDelete.forEach(e => delete duplicate[e])
      this.$http.post(`${process.env.VUE_APP_API_URL}/students`, duplicate)
        .then(response => this.$router.push(`/student/view/${response.data.id}`))
        .catch(err => {
          this.$store.commit('snackMe', {
            color: 'error',
            text: 'Erreur lors de la duplication',
            value: true,
          })
          console.log(err)
        })
    },
    createStudent() {
      this.disableBtn = true
      this.studentInfos.naissance = new Date(this.studentInfos.naissance)
      this.$http.post(`${process.env.VUE_APP_API_URL}/students`, this.studentInfos)
        .then(response => {
          if (this.partenaire) {
            const partStud = this.partenaires.find(e => e.id === this.partenaire)
            console.log(partStud)
            partStud.students.push(response.data.id)
            this.$http.put(`${process.env.VUE_APP_API_URL}/partenaires/${this.partenaire}`, { students: partStud.students })
              .then()
              .catch(err => {
                this.$store.commit('snackMe', {
                  color: 'error',
                  text: 'Erreur lors du lien avec le partenaire',
                  value: true,
                })
                console.log(err)
              })
          }
          this.$store.commit('snackMe', {
            color: 'success',
            text: 'Stagiaire bien créé',
            value: true,
          })
          this.$router.push(`/student/view/${response.data.id}`)
        })
        .catch(err => {
          this.$store.commit('snackMe', {
            color: 'error',
            text: 'Erreur lors de la création de l\'étudiant',
            value: true,
          })
          console.error(err)
        })
        .finally(
          this.disableBtn = false,
        )
    },
    updateTuteur(e) {
      const matchingTut = this.tuteurs.find(el => el.id === e)
      delete matchingTut.students
      this.studentInfos.tuteurs = [matchingTut]
    },
    deleteStudent() {
      this.$http.delete(`${process.env.VUE_APP_API_URL}/students/${this.$route.params.id}`)
        .then(() => {
          this.$store.commit('snackMe', {
            color: 'success',
            text: 'Apprenant supprimé avec succès',
            value: true,
          })
          this.$router.push('/students')
        }).catch(err => {
          console.log(err)
          this.$store.commit('snackMe', {
            color: 'error',
            text: 'Erreur lors de la suppression',
            value: true,
          })
        })
        .then(() => {
          this.dialog = false
        })
    },
    updateStudent() {
      this.$http.put(`${process.env.VUE_APP_API_URL}/students/${this.studentInfos.id}`, this.studentInfos)
        .catch(err => {
          this.$store.commit('snackMe', {
            color: 'error',
            text: 'Erreur lors de la mise à jour',
            value: true,
          })
          console.error(err)
        })
        .then(() => {
          this.$store.commit('snackMe', {
            color: 'success',
            text: 'Stagiaire bien mis à jour',
            value: true,
          })
        })
    },
    updatePartenaire() {
      this.partenaires.forEach(partenaire => {
        if (partenaire.students.includes(this.studentInfos.id)) {
          let studArr = partenaire.students
          studArr = studArr.filter(e => e !== this.studentInfos.id)
          this.$http.put(`${process.env.VUE_APP_API_URL}/partenaires/${partenaire.id}`, { students: studArr })
            .then(
              this.$store.commit('snackMe', {
                color: 'success',
                text: 'Partenaire mis à jour',
                value: true,
              }),
            )
            .catch(err => {
              this.$store.commit('snackMe', {
                color: 'error',
                text: 'Erreur lors du lien avec le partenaire',
                value: true,
              })
              console.log(err)
            })
        }
      })
      const partStud = this.partenaires.find(e => e.id === this.editPartenaire)
      partStud.students.push(this.studentInfos.id)
      this.$http.put(`${process.env.VUE_APP_API_URL}/partenaires/${this.editPartenaire}`, { students: partStud.students })
        .then(
          this.$store.commit('snackMe', {
            color: 'success',
            text: 'Partenaire mis à jour',
            value: true,
          }),
        )
        .catch(err => {
          this.$store.commit('snackMe', {
            color: 'error',
            text: 'Erreur lors du lien avec le partenaire',
            value: true,
          })
          console.log(err)
        })
    },
  },
  setup() {
    const status = [
      {
        value: 'avantFormation',
        color: 'primary',
        text: 'Entrée en Formation',
      },
      {
        value: 'sortieFormation',
        color: 'primary',
        text: 'Sorti de Formation',
      },
      {
        value: 'enFormation',
        color: 'primary',
        text: 'Formation en cours',
      },
      {
        value: 'autre',
        color: 'primary',
        text: 'Autre',
      },
    ]

    return {
      status,
      icons: {
        mdiAlertOutline,
        mdiTrashCanOutline,
        mdiCloudUploadOutline,
        mdiCalendar,
        mdiCurrencyEur,
        mdiAccountPlus,
        mdiAccountArrowRight,
        mdiAccountMultiplePlus,
      },
    }
  },
}
</script>
